exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/AboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-apply-jsx": () => import("./../../../src/pages/Apply.jsx" /* webpackChunkName: "component---src-pages-apply-jsx" */),
  "component---src-pages-case-studies-border-police-jsx": () => import("./../../../src/pages/caseStudies/BorderPolice.jsx" /* webpackChunkName: "component---src-pages-case-studies-border-police-jsx" */),
  "component---src-pages-case-studies-kaufland-jsx": () => import("./../../../src/pages/caseStudies/Kaufland.jsx" /* webpackChunkName: "component---src-pages-case-studies-kaufland-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/Contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-banking-jsx": () => import("./../../../src/pages/industries/Banking.jsx" /* webpackChunkName: "component---src-pages-industries-banking-jsx" */),
  "component---src-pages-industries-emergency-services-jsx": () => import("./../../../src/pages/industries/EmergencyServices.jsx" /* webpackChunkName: "component---src-pages-industries-emergency-services-jsx" */),
  "component---src-pages-industries-government-jsx": () => import("./../../../src/pages/industries/Government.jsx" /* webpackChunkName: "component---src-pages-industries-government-jsx" */),
  "component---src-pages-industries-health-jsx": () => import("./../../../src/pages/industries/Health.jsx" /* webpackChunkName: "component---src-pages-industries-health-jsx" */),
  "component---src-pages-industries-law-enforcement-jsx": () => import("./../../../src/pages/industries/LawEnforcement.jsx" /* webpackChunkName: "component---src-pages-industries-law-enforcement-jsx" */),
  "component---src-pages-industries-telecommunications-jsx": () => import("./../../../src/pages/industries/Telecommunications.jsx" /* webpackChunkName: "component---src-pages-industries-telecommunications-jsx" */),
  "component---src-pages-industries-utilities-jsx": () => import("./../../../src/pages/industries/Utilities.jsx" /* webpackChunkName: "component---src-pages-industries-utilities-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-policies-code-of-conduct-jsx": () => import("./../../../src/pages/policies/CodeOfConduct.jsx" /* webpackChunkName: "component---src-pages-policies-code-of-conduct-jsx" */),
  "component---src-pages-policies-dei-policy-jsx": () => import("./../../../src/pages/policies/DEIPolicy.jsx" /* webpackChunkName: "component---src-pages-policies-dei-policy-jsx" */),
  "component---src-pages-policies-environmental-policy-jsx": () => import("./../../../src/pages/policies/EnvironmentalPolicy.jsx" /* webpackChunkName: "component---src-pages-policies-environmental-policy-jsx" */),
  "component---src-pages-policies-quality-security-jsx": () => import("./../../../src/pages/policies/QualitySecurity.jsx" /* webpackChunkName: "component---src-pages-policies-quality-security-jsx" */),
  "component---src-pages-policies-sustainable-procurement-jsx": () => import("./../../../src/pages/policies/SustainableProcurement.jsx" /* webpackChunkName: "component---src-pages-policies-sustainable-procurement-jsx" */),
  "component---src-pages-policies-whistleblowing-jsx": () => import("./../../../src/pages/policies/Whistleblowing.jsx" /* webpackChunkName: "component---src-pages-policies-whistleblowing-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/Privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-supportpage-customer-satisfaction-survey-jsx": () => import("./../../../src/pages/supportpage/CustomerSatisfactionSurvey.jsx" /* webpackChunkName: "component---src-pages-supportpage-customer-satisfaction-survey-jsx" */),
  "component---src-pages-supportpage-support-jsx": () => import("./../../../src/pages/supportpage/Support.jsx" /* webpackChunkName: "component---src-pages-supportpage-support-jsx" */)
}

